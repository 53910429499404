import ProfilePic from "../assets/profile-pics/profile-7.png";
import ProfilePicBorder from "../assets/profile-pics/pfp-border-1.svg";

import "../styles/LandingPage.css";

function LandingPage() {
  return (
    <div className="container">
      <div className="floating-box">
        <div className="left-column">
          <div className="profile-stack">
            <img
              className="pfp-border"
              src={ProfilePicBorder}
              alt="pfp border"
            />
            <img
              className="pfp-picture"
              src={ProfilePic}
              alt="this is supposed to be a picture of me"
            />
          </div>
        </div>
        <div className="right-column">
          <div className="title">
            <p className="typed-out">hi! my name is emily lin.</p>
          </div>
          <div className="description">
            I’m a senior software engineering student at the University of
            Waterloo. In my free time, I love to travel, hike, run, and climb!
            <br></br>
            <br></br>
            I’m actively searching for new grad opportunities :) If you'd like to chat, feel free to reach out at <a href="mailto:emilyylin17@gmail.com">emilyylin17@gmail.com</a> or on <a href="https://www.linkedin.com/in/emilyshuyanlin/">Linkedin</a>!
            <br></br>
          </div>
          <div className="social-media-icons">
            <a href="https://github.com/emilyylin">
              <div className="social-media-icon" id="github" />
            </a>
            <a href="https://www.linkedin.com/in/emilyshuyanlin/">
              <div className="social-media-icon" id="linkedin" />
            </a>
            <a href="mailto:emilyylin17@gmail.com">
              <div className="social-media-icon" id="email" />
            </a>
            <a href="https://drive.google.com/file/d/1soTPATJ6MjuDRDXQOn6LnwhyrKdF0FAw/view?usp=sharing">
              <div className="social-media-icon" id="resume" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
